import React from "react";
import Navi from "./navi";
import Logo from "../img/logo_desktop.png";
import LogoFooter from "../img/logo_image_footer.png";
import OtroFooter from "../img/otro_image_footer.png";
import Pdf from "../img/icono-PDF.png";
import Video from "../img/reproductor-de-video.png"
import Pre from "../img/carpeta.png"

function Documentacion() {
  return (
    <>
      <div className="contain">
        <div className="mobile-version"></div>
        <div className="logo" />
        <div className="info">
          <div className="img-logo main-logo">
            <img src={Logo} width={413} />
          </div>
          <Navi />
          <h1 className="tipo-01">
            <b>Documentación</b>
          </h1>
          <br/>
          {/* className="tipo-01" */}
          <p><b>Videos</b></p>
          <div className="talleres01">
          <ul>
          <li><img src={Video} width={25}/> <a className="link-style" target="_blank" rel="noopener noreferrer" href="https://drive.google.com/drive/folders/18pOeU0zQtkeUGNhXGW3xwr5TDJZz2r8J?usp=sharing">Ver Videos</a></li>
          </ul>
          </div>
          <p><b>Presentaciones</b></p>
          <div className="talleres03">
          <ul>
          <li><img src={Pdf} width={25}/> <a className="link-style" target="_blank" rel="noopener noreferrer" href="https://drive.google.com/drive/folders/1RRaeQ0YLDanV796AR3OLzhlWyjFqUTpe?usp=sharing">Ver Presentaciones</a></li>
          </ul>
          </div>
          <p><b>Documentación general</b></p>
          <div className="talleres02">
          <ul>
          <li><img src={Pre} width={25}/> <a className="link-style" target="_blank" rel="noopener noreferrer" href="https://drive.google.com/drive/folders/13LZ0DKze9qj7sni42DbZJX8TlEdYsDdp?usp=sharing">Ver Documentación</a></li>
          </ul>
          </div>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
        </div>
      </div>
      <footer>
        <div className="border-footer">Un programa de:</div>
        <div className="img-logo-footer">
          <img src={LogoFooter} width={256} />
        </div>
        <div className="border-footer-territoria">
          <p>Ejecutados con el apoyo de:</p>
          <div className="img-logo-footer">
            <img src={OtroFooter} width={100} />
          </div>
        </div>
      </footer>
    </>
  );
}

export default Documentacion;